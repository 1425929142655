//------------------------------------------------------------------------
// Dynamically show and hide relevant subfilters
// based on category filter selection.
//------------------------------------------------------------------------
'use strict';
import ExpandToggle from '@threespot/expand-toggle';

class SearchFilters {
  constructor() {
    this.categoryDropdowns = document.querySelectorAll('.Search-form-select');
    this.categoryRadios = document.querySelectorAll(
      '.Search-filters-categories [type="radio"]'
    );
    // Get current category on load
    // (if multiple dropdowns exists, we can assume they have the same value and just check the first one)
    if (this.categoryDropdowns.length) {
      this.selectedCategory = this.categoryDropdowns[0].value;
    }
    this.setupMainToggle();
    this.setupFilters();
  }

  setupMainToggle() {
    // NOTE: Breakpoint must match $layout-sidebar-bp
    this.mediaQueryList = window.matchMedia('(max-width: 859px)'); // subtract 1px for max-width media queries
    this.mainToggle = document.querySelector(
      '.Search-filters-header[data-expands]'
    );

    // Listen for breakpoint change
    this.mediaQueryList.addListener((evt) => {
      if (evt.matches) {
        this.mainExpandToggle = new ExpandToggle(this.mainToggle);
      } else {
        this.mainExpandToggle.destroy();
      }
    });

    // Init on load
    if (this.mediaQueryList.matches) {
      this.mainExpandToggle = new ExpandToggle(this.mainToggle);
    }
  }

  setupFilters() {
    this.filterToggles = document.querySelectorAll(
      '.Search-filters-form [data-expands]'
    );
    this.subfilters = document.querySelectorAll('.Search-filters-subfilters');

    // NOTE: Since subfilters will be expandable in the future, we will need to store references
    //       to them in order to expanda/collapse programmatically.
    this.filterExpandToggles = [];

    // Init expand toggles
    this.filterToggles.forEach((el) => {
      let expandToggle = new ExpandToggle(el);
      this.filterExpandToggles.push(expandToggle);
    });

    // Listen for category dropdown change
    this.categoryDropdowns.forEach((el) => {
      el.addEventListener('change', this.dropdownChangeHandler.bind(this));
    });

    // Listen for category radio button change
    this.categoryRadios.forEach((el) => {
      el.addEventListener('change', this.radioChangeHandler.bind(this));
    });
  }

  categoryChangeHandler(category) {
    // Update subfilters
    this.showSubfilter(category);

    // Update main filter menu height if it exists (i.e. in mobile layout)
    if (this.mainExpandToggle) {
      this.mainExpandToggle.updateExpandedHeight();
    }
  }

  dropdownChangeHandler(evt) {
    // Get category
    let category = evt.target.value;

    // Check matching radio button
    let radio = document.querySelector(
      `.Search-filters-categories [value="${category}"]`
    );
    if (radio) {
      radio.checked = true;
    }

    this.categoryChangeHandler(category);
  }

  radioChangeHandler(evt) {
    // Get category
    let category = evt.target.value;

    // Check matching category dropdown option
    this.categoryDropdowns.forEach((el) => {
      el.value = category;
    });

    this.categoryChangeHandler(category);
  }

  // Show subfilters for the selected category
  showSubfilter(category) {
    // Hide all subfilters
    this.subfilters.forEach((el) => {
      el.setAttribute('aria-hidden', 'true');
      // NOTE: The Rails app will ignore subfilter fields for inactive categories,
      //       otherwise we would need to uncheck any hidden inputs.
    });

    // Show associated filters
    if (category.length) {
      let activeSubfilter = document.getElementById('filters_' + category);
      // Not all categories have subfilters so check if they exist
      if (activeSubfilter) {
        activeSubfilter.setAttribute('aria-hidden', 'false');
      }
    }
  }
}

// Init on search page
if (document.querySelector('.Search')) {
  new SearchFilters();
}
