// Image Generator
// Displays an image when a user selects a day, severity, and retinal disease value
// https://github.com/Threespot/blindness/issues/700

// After every change
// We will update the string that will retrieve the image
// We can also send an API request that gets us the data initially

export default class ImageGenerator {
  constructor() {
    this.el = document.querySelector('[data-controller="image-generator"]');

    if (!this.el) {
      return false;
    }

    this.retinalControls = this.el.querySelector('[data-target="retinal-diseases"]');
    this.severityControls = this.el.querySelector('[data-target="severity-controls"]');
    this.dayControls = this.el.querySelector('[data-target="day-controls"]');
    this.imageName = this.el.querySelector('[data-target="image-name"]');
    this.form = this.el.querySelector('[data-target="form"]');
    this.email = this.el.querySelector('[data-target="email"]');
    this.submitBtn = this.el.querySelector('[data-target="submit"]');
    this.original = this.el.querySelector('[data-target="original"]');

    this.url = this.form.getAttribute('action');

    this.updateSelectedValues();
    this.init();
  }

  init() {

    // Updates the hidden imageName input field with the filename
    this.imageName.value = this.selectedValues.join("-") + '.jpg';

    this.el.addEventListener("change", this.handleChange.bind(this));
    this.form.addEventListener("submit", this.handleSubmit.bind(this));
    this.email.addEventListener('input', this.handleInput.bind(this));
  }

  handleInput(evt) {
    this.email.value = evt.target.value;
  }

  handleSubmit(evt) {
    evt.preventDefault();

    let csrfToken = this.el.querySelector("[name='authenticity_token']").value;

    if (!csrfToken) {
      console.error('CSRF TOKEN ERROR');
      return false;
    }

    // Kickoff spinner
    this.submitBtn.setAttribute('data-status', 'loading');

    fetch(this.url, {
      method: 'POST',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        image_name: this.imageName.value,
        email: this.email.value
      })
    }).then(response => {
      return response.json();
    }).then(data => {

      if (data.status === 406) {
        throw new Error(`Status Code: ${data.status} - ${data.message}`);
      }

      // Change message to submitted
      this.submitBtn.setAttribute('data-status', 'success');
      console.log(data);
    }).catch(error => {
      this.submitBtn.setAttribute('data-status', 'error');
      // Add error message that indicates that it wasn't send successfully
      console.log(error);
    });
  }

  handleChange(evt) {
    let { target } = evt;

    if (target.tagName === "INPUT") {
      this.updateSelectedValues();
      this.updateImage();
    }
  }

  /**
   * Retrieves the parts of the filename from the radio buttons and store it inside of an array
   */
  updateSelectedValues() {
    this.selectedValues = [
      this.retinalControls.querySelector('input[type="radio"]:checked').value,
      this.dayControls.querySelector('input[type="radio"]:checked').value,
      this.severityControls.querySelector('input[type="radio"]:checked').value,
    ];
  }

  updateImage() {
    // update original image based on night/day selection
    let nightOrDay = this.dayControls.querySelector('input[type="radio"]:checked').value;
    if (nightOrDay === "day") {
      this.original.classList.remove("-night");
      this.original.classList.add("-day");
    } else {
      this.original.classList.remove("-day");
      this.original.classList.add("-night");
    }

    let imgName = this.selectedValues.join("-");
    let prevImg = this.el.querySelector('img[data-selected="true"]');
    let img = this.el.querySelector(`[data-img-name="${imgName}"]`);

    this.imageName.value = imgName + '.jpg';

    // Grab the image that is stored in the data-src attribute
    if (img.getAttribute("src") !== img.getAttribute("data-src")) {
      img.setAttribute("src", img.getAttribute("data-src"));
    }

    prevImg.setAttribute("data-selected", "false");
    img.setAttribute("data-selected", "true");
  }
}
