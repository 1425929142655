"use strict";

import ObjectFitImage from "@threespot/object-fit-image";

const init = () => {
  document.querySelectorAll(".bg-image").forEach(
    el =>
      new ObjectFitImage(el, {
        visuallyHiddenClass: "u-screenreader"
      })
  );
};

// Init on initial page load
init();
