//------------------------------------------------------------------------
// Find a Chapter strata
// https://github.com/Threespot/blindness/issues/90
//
// Note: Could use <datalist> once fully supported
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/datalist
// https://caniuse.com/#feat=datalist
//------------------------------------------------------------------------
class FindChapter {
  constructor(el) {
    this.el = el;
    this.chapterWrapper = el.querySelector('.FindChapter-info');

    if (!this.chapterWrapper) {
      return false;
    }

    try {
      // Find ContentList element in order to
      // show/hide based on presence of related resource
      this.contentList = this.chapterWrapper.querySelector('.ContentList');

      // Find Chapter resource content wrap to hide if no resource associated with chapter
      this.resourceContent = this.chapterWrapper.querySelector('.ContentList-content');

      // Find Chapter contact info wrap to display if no resource associated with chapter
      this.chapterContact = this.chapterWrapper.querySelector('.ContentList-contact');

      // Find elements that need to be populated with chapter data
      this.chapterName = this.chapterWrapper.querySelector('.FindChapter-info-name');
      this.contentImage = this.chapterWrapper.querySelector('.ContentList-image-tag');
      this.contentDate = this.chapterWrapper.querySelector('.ContentList-meta-date');
      this.contentType = this.chapterWrapper.querySelector('.ContentList-meta-type');
      this.contentLink = this.chapterWrapper.querySelector('.ContentList-link');
      this.contentDescription = this.chapterWrapper.querySelector('.ContentList-description');
      this.contactLink = this.chapterWrapper.querySelector('.ContentList-link.-contact');

      this.contactDescription = this.chapterWrapper.querySelector('.ContentList-contact-description');
      this.contactState = this.chapterWrapper.querySelector('.ContentList-contact-state');
      this.contactCity = this.chapterWrapper.querySelector('.ContentList-contact-city');
      this.contactStreet = this.chapterWrapper.querySelector('.ContentList-contact-street');
      this.contactEmail = this.chapterWrapper.querySelector('.ContentList-contact-email');
      this.contactPhone = this.chapterWrapper.querySelector('.ContentList-contact-phone');
      this.contactFacebook = this.chapterWrapper.querySelector('.ContentList-contact-facebook');
      this.chapterButton = this.chapterWrapper.querySelector('.FindChapter-info-cta');

      // Form elements
      this.findChapterWrapper = el.querySelector('.FindChapter-initial');
      this.form = this.findChapterWrapper.querySelector('.FindChapter-form');
      this.select = this.form.querySelector('.FindChapter-form-select');
      this.errorMsg = this.form.querySelector('.FindChapter-form-error');
      this.submit = this.form.querySelector('.FindChapter-form-submit');
    } catch (error) {
      console.warn(
        'Unable to query necessary DOM elements. Ensure that the specific elements and classes exist, error'
      );
      return; //exit constructor and stop initialization of the FInd a Chapter script
    }

    // Prevent default on form submit
    this.form.addEventListener('submit', evt => this.submitHandler(evt));

    // Hide error message on select change
    this.select.addEventListener("change", this.hideErrorMsg.bind(this));
  }

  showErrorMsg() {
    this.errorMsg.classList.remove('u-hide');
  }

  hideErrorMsg() {
    this.errorMsg.classList.add('u-hide');
  }

  // Plain JS AJAX post function
  // https://plainjs.com/javascript/ajax/send-ajax-get-and-post-requests-47/
  postAjax(url, data, success) {
    var xhr = window.XMLHttpRequest
      ? new XMLHttpRequest()
      : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('POST', url);
    xhr.onreadystatechange = function() {
      if (xhr.readyState > 3 && xhr.status == 200) {
        success(xhr.responseText);
      }
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(data);
    return xhr;
  }

  // Get chapter data from GraphQL endpoint
  getData(chapterId) {
    this.postAjax(
      '/graphql',
      JSON.stringify({
        query:
          '{ chapter(id:' +
          chapterId +
          '){ id name path city_and_state facebook_page_url street_address main_contact_phone main_contact_email banner_image_url resource { id title type featured_image_url published_at description path }}}'
      }),
      response => {
        var data = JSON.parse(response)['data'];
        this.updateChapterInfo(data);
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * Chapter info object api response
   * @typedef {Object} ChapterInfo
   * @property {string} id - unique identifier of the chapter
   * @property {string} name - name of the chapter
   * @property {string} path - root relative path of the chapter page
   * @property {string} city_and_state - formatted city and/or state of the Chapter
   * @property {string} facebook_page_url - facebook url of the Chapter
   * @property {string} street_address - street address of the Chapter
   * @property {string} main_contact_phone - phone number of the Chapter
   * @property {string} main_contact_email - email address of the Chapter
   * @property {string} banner_image_url - banner image of the Chapter
   * @property {object} resource - related resource to chaprter
   * @property {string} resource.description - description of the resource
   * @property {string} resource.feature_image_url - root relative path of the url
   * @property {string} resource.id - the unique identifier of the Resource
   * @property {string} resource.path - root relative path of the resource page.
   * @property {string} resource.published_at - published date of the resource
   * @property {string} resource.title - title of the resource
   * @property {string} resource.type - type of the resource
   */

  /**
   * Update Chapter Info
   * updates cached DOM elements with/based on passed data values
   * and switches component to result state
   * @param {ChapterInfo} data - response object from api endpoint
   * @return null
   */
  updateChapterInfo(data) {
    console.log(data['data']);
    // Add class to main wrapper
    this.el.classList.add('has-content');

    // Integrate Chapter data
    this.chapterName.textContent = data.chapter['name'];
    this.chapterButton.textContent = data.chapter['name'];
    this.chapterButton.href = data.chapter['path'];

    // Integrate related resource data
    if (data.chapter.resource) {
      if (this.contentImage) {
        this.contentImage.srcset = data.chapter.resource['featured_image_url'];
      }
      this.contentLink.href = data.chapter.resource['path'];
      this.contentLink.textContent = data.chapter.resource['title'];
      this.contentType.textContent = data.chapter.resource['type'];
      this.contentDate.textContent = data.chapter.resource['published_at'];
      this.contentDescription.innerHTML = data.chapter.resource['description'];
    } else {
      this.contactLink.href = data.chapter['path'];
      this.contactLink.textContent = data.chapter['name'];
      this.contentImage.srcset = data.chapter['banner_image_url'];
      if (data.chapter['description']) {
        this.contactDescription.innerHTML = data.chapter['description'];
      }
      this.contactStreet.innerHTML = data.chapter['street_address'];
      this.contactCity.innerHTML = data.chapter['city_and_state'];
      this.contactPhone.innerHTML = data.chapter['main_contact_phone'];
      this.contactEmail.innerHTML = data.chapter['main_contact_email'];
      this.contactFacebook.innerHTML = data.chapter['facebook_page_url'];
    }

    // If no resource is present, use chapter contact info
    if (data.chapter.resource) {
      this.chapterContact.classList.add('-hidden');
    } else {
      this.resourceContent.classList.add('-hidden');
    }

    // Show chapter info
    this.findChapterWrapper.setAttribute('aria-hidden', true);
    // Hide find a chapter form
    this.chapterWrapper.setAttribute('aria-hidden', false);
  }

  submitHandler(evt) {
    evt.preventDefault();
    let chapterId = this.select.value;

    // Show error if no selected chapter
    if (!chapterId) {
      this.showErrorMsg();
      return false;
    }

    this.getData(chapterId);
  }
}

// Init
document.querySelectorAll('.FindChapter').forEach(el => new FindChapter(el));
