'use strict';

import throttle from 'lodash/throttle';

class BackToTop {
  constructor(el) {
    var self = this;
    this.el = el;
    this.lastScrollTop = 0;
    this.parent = document.querySelector('html');
    self.parent.classList.add('scroll-down');

    this.addListeners();
  }

  addListeners() {
    var self = this;
    // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
    document.addEventListener(
      'scroll',
      throttle(self.toggleClasses.bind(this), 150),
      false
    );
  }

  toggleClasses() {
    var self = this;
    // or window.addEventListener("scroll"....
    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
    if (st > self.lastScrollTop) {
      // downscroll code
      self.parent.classList.add('scroll-down');
      self.parent.classList.remove('scroll-up');
    } else {
      self.parent.classList.remove('scroll-down');
      self.parent.classList.add('scroll-up');
      // upscroll code
    }
    if (window.innerHeight < self.lastScrollTop) {
      self.parent.classList.add('past-fold');
    } else {
      self.parent.classList.remove('past-fold');
    }
    self.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }
}

// Init
const nodes = document.querySelectorAll('.BackToTop');
for (var i = 0, len = nodes.length; i < len; i++) {
  new BackToTop(nodes[i]);
}

export default BackToTop;
