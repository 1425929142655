//------------------------------------------------------------------------
// Filter chapter listing by state
// https://github.com/Threespot/blindness/issues/119
//
// Note: Could use <datalist> once fully supported
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/datalist
// https://caniuse.com/#feat=datalist
//------------------------------------------------------------------------
class ChapterFilter {
  constructor(el) {
    this.el = el;
    this.isFiltered = false;
    this.select = this.el.querySelector("select");
    this.button = this.el.querySelector(".ChapterFilter-button");
    this.buttonFilterText = this.button.textContent;
    this.buttonResetText = this.button.getAttribute("data-reset-text") || "Reset";
    this.resultsCount = document.querySelectorAll(".Results-count");
    this.resultsLocation = document.querySelectorAll(".Results-location");
    this.resultsOrigLocationText = this.resultsLocation[0].textContent;

    this.setup();
  }

  setup() {
    // Find chapter listing items
    this.chapterItems = document.querySelectorAll(".ChapterList-item");

    // Create placeholder array for list of states that have chapters
    this.statesWithChapters = [];

    this.chapterItems.forEach(el => {
      el.setAttribute("aria-hidden", "false");
      this.statesWithChapters.push(el.getAttribute("data-state"));
    });

    // Remove any duplicates from states array
    // Since this is a relatively small array that won’t have many duplicates,
    // we can use this simplest method without worrying about performance.
    // https://stackoverflow.com/a/9229821/673457
    this.statesWithChapters = this.statesWithChapters.filter((state, i) => {
      return this.statesWithChapters.indexOf(state) === i;
    });

    // Remove any states from select menu that do not have chapters.
    // Note: We could also try to do this on the back end
    this.select.querySelectorAll("option").forEach((option, i) => {
      // Note: Don’t remove the first placeholder option
      if (i > 0 && this.statesWithChapters.indexOf(option.value) === -1) {
        option.remove();
      }
    });

    // Reset button when select is changed
    this.select.addEventListener("change", () => {
      this.button.classList.remove("is-active");
      this.button.textContent = this.buttonFilterText;
      this.isFiltered = false;
    });

    // Apply/reset filter button click event
    this.button.addEventListener("click", this.filterToggle.bind(this));
  }

  filter() {
    let resultCount = 0;
    let currentStateAbbr = this.select.selectedOptions[0].value;
    let currentStateName = this.select.selectedOptions[0].text;

    // Do nothing if no state selected
    if (!currentStateAbbr) {
      return false;
    }

    this.chapterItems.forEach(el => {
      if (el.getAttribute("data-state") == currentStateAbbr) {
        resultCount += 1;
        el.setAttribute("aria-hidden", "false");
      } else {
        el.setAttribute("aria-hidden", "true");
      }
    });

    this.updateResultsText(resultCount, "in " + currentStateName);

    this.isFiltered = true;
    this.button.classList.add("is-active");
    this.button.textContent = this.buttonResetText;
  }

  // Update results text, which is visually hidden (has `role="alert" aria-live="polite"`)
  updateResultsText(count, locationText) {
    this.resultsCount.forEach(el => {
      el.textContent = count;
    });

    this.resultsLocation.forEach(el => {
      el.textContent = locationText;
    });
  }

  // Reset filter
  resetFilter() {
    // Show all items
    this.chapterItems.forEach(el => {
      el.setAttribute("aria-hidden", "false");
    });

    this.updateResultsText(
      this.chapterItems.length,
      this.resultsOrigLocationText
    );

    // Reset original select
    this.select.value = "";

    this.isFiltered = false;
    this.button.classList.remove("is-active");
    this.button.textContent = this.buttonFilterText;
  }

  filterToggle() {
    if (this.isFiltered) {
      this.resetFilter();
    } else {
      this.filter();
    }
  }
}

// Init
document.querySelectorAll(".ChapterFilter").forEach(el => new ChapterFilter(el));
