//------------------------------------------------------------------------
// Dynamically load Soundcloud player using oEmbed API
// https://developers.soundcloud.com/docs/oembed
//
// TODO: Pause currently playing audio/video players when starting a new one
//------------------------------------------------------------------------
"use strict";

class SoundCloudPlayer {
  constructor(el) {
    this.el = el;

    this.options = {
      color: "#005aa3",// $c-blue
      auto_play: false,
      hide_related: true,
      show_comments: false,
      show_user: true,
      show_reposts: false,
      show_teaser: false,
      teaserVisible: false,
      visual: false// better for accessiblity, no text over cover art
    };

    // Get SoundCloud URL
    this.soundcloudURL = this.el.getAttribute("data-soundcloud");

    // Find element where player should be injected, default to this.el
    this.playerEl = this.el.querySelector("[data-soundcloud-player]") || this.el;

    // Get player data using oEmbed API
    this.getPlayerData(data => {
      this.data = data;
      this.init();
    });
  }

  init() {
    // Update poster image
    this.imageEl = this.el.querySelectorAll('[data-soundcloud-image]');
    this.imageEl.forEach(el => {
      el.src = this.data.thumbnail_url;
    });

    // Update title text
    this.titleEl = this.el.querySelectorAll('[data-soundcloud-title]');
    this.titleEl.forEach(el => {
      el.textContent = this.data.title;
    });

    // Find link overlay
    this.linkEl = this.el.querySelector(".SoundCloud-link");

    if (!this.linkEl) {
      console.warn("Missing SoundCloud player link overlay, loading player immediately", this.soundcloudURL);
      this.buildPlayer();
      return false;
    }

    // Change link to button for better accessibility
    this.linkEl.setAttribute("role", "button");

    // Load player on click
    this.linkEl.addEventListener("click", evt => {
      evt.preventDefault();

      // Create the player if not already present
      if (!this.player) {
        // Player should autoplay to prevent users from having to click twice
        this.buildPlayer(true);
      }
    });
  }

  // Plain JS AJAX request
  // https://plainjs.com/javascript/ajax/making-cors-ajax-get-requests-54/
  getCORS(url, success, error) {
    var xhr = new XMLHttpRequest();
    if (!("withCredentials" in xhr)) xhr = new XDomainRequest(); // fix IE8/9
    xhr.open("GET", url);
    xhr.onload = success;
    xhr.onerror = error;
    xhr.send();
    return xhr;
  }

  getPlayerData(callback) {
    // Convert options to URL params
    // https://stackoverflow.com/a/41916123/673457
    let params = Object.keys(this.options)
      .map(key => `${key}=${encodeURIComponent(this.options[key])}`)
      .join("&");

    let url = `https://soundcloud.com/oembed?format=json&url=${encodeURIComponent(
      this.soundcloudURL
    )}&${params}`;

    this.getCORS(
      url,
      request => {
        let response = request.currentTarget.response || request.target.responseText;
        callback(JSON.parse(response));
      },
      function() {
        console.warn("Failed to get SoundCloud oEmbed data");
      }
    );
  }

  // Insert iframe player
  buildPlayer(autoplay) {
    // Remove default “visual=true” param SoundCloud adds by default
    let iframeHTML = this.data.html.replace("?visual=true&", "?");

    // Update autoplay value
    if (autoplay) {
      iframeHTML = iframeHTML.replace("auto_play=false", "auto_play=" + autoplay);
    }

    // Determine if this is a single track or a playlist
    let regex = /(?:api\.soundcloud\.com%2F)(\w+)/;
    let matches = iframeHTML.match(regex);
    if (matches[1]) {
      this.playerEl.classList.add("SoundCloud-player--" + matches[1]);
    }

    this.playerEl.innerHTML = iframeHTML;

    // Focus the frame since the link is now hidden
    this.playerEl.querySelector("iframe").focus();
  }
}

const init = () => {
  document.querySelectorAll("[data-soundcloud]").forEach(el => {
    new SoundCloudPlayer(el);
  });
};

init();
