// Utility Functions
"use strict";

module.exports = {
  // Get URL params, return as Object
  getParams: function( url ) {
    var params = {};
    var hashes = url.slice( url.indexOf('?') + 1 ).split('&');
    for( var i = 0; i < hashes.length; i++ ) {
      var hash = hashes[i].split('=');
      var hashVal = hash[1];
      if ( typeof hashVal !== 'undefined' ) {
        // Remove any hashes from param value
        if ( hashVal.search('#') > 0 ) {
          hashVal = hashVal.split('#')[0];
        }
        params[ hash[0] ] = hashVal;
      }
    }
    return params;
  },

  // Check if IE8 or lower
  isOldIE: document.documentElement.className.indexOf('lte-ie8') >= 0,

  // Check if iOS
  isIOS: document.documentElement.className.indexOf('ios') >= 0,

  // Detect version of Android
  // @param {Strng} version (e.g. 4, 4.1, 4.1.1)
  isAndroid: function( version ) {
    var r = new RegExp( 'Android' + ( typeof version != 'undefined' ? '\\s' + version : '' ), 'i' );
    return r.test( navigator.userAgent );
  },

  // Return string of SVG icon markup
  svg: function( iconName, width, height, fallback ) {
    if ( typeof iconName == 'undefined' || iconName.length === 0 ) {
      return false;
    }

    var svgWidth = ( !!width ? 'width="' + width + '"' : '' );
    var svgHeight = ( !!height ? 'height="' + height + '"' : '' );
    var svgFallback = ( !!fallback ? '<foreignObject width="0" height="0"><div class="icon-fallback icon-' + fallback + '"></div></foreignObject>' : '' );

    return '<svg class="icon" version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" preserveAspectRatio="xMidYMid meet" ' + svgWidth + ' ' + svgHeight + ' aria-hidden="true" role="presentation" focusable="false"><use xlink:href="#icon-' + iconName + '" />' + svgFallback + '</svg>';
  },

  // Get browser prefixed transition/animation event
  // From window.Modernizr via http://davidwalsh.name/css-animation-callback
  getPrefixedEvent: function( type ) {
    var t;
    var el = document.createElement('fakeelement');

    var transitions = {
      'transition':'transitionend',
      'OTransition':'oTransitionEnd',
      'MozTransition':'transitionend',
      'WebkitTransition':'webkitTransitionEnd'
    };

    var animations = {
      'animation':'animationend',
      'OAnimation':'oAnimationEnd',
      'MozAnimation':'animationend',
      'WebkitAnimation':'webkitAnimationEnd'
    };

    var eventType = transitions;

    if ( type === 'animation' ) {
      eventType = animations;
    }

    for( t in eventType ) {
      if ( el.style[t] !== undefined ) {
        return eventType[t];
      }
    }

    return false;
  },

  // Keycode Map
  Key: {
    DOWN: 40,
    ENTER: 13,
    ESCAPE: 27,
    LEFT: 37,
    PAGE_DOWN: 34,
    PAGE_UP: 33,
    RIGHT: 39,
    SHIFT: 16,
    SPACE: 32,
    TAB: 9,
    UP: 38
  }
};
