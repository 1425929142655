import Cookies from "../lib/cookie";
class MyChapter {
  constructor(el) {
    this.el = el;
    this.select = el.querySelector(".MyChapter-select");
    this.selected = el.querySelector(".MyChapter-select option:checked");
    this.link = el.querySelector(".MyChapter-link");
    this.tmpWidth = el.querySelector(".MyChapter-tmpWidth");
    this.tmpWidthOption = el.querySelector(".MyChapter-tmpWidth-option");

    const existingChapterId = Cookies.get("chapter_id");

    if (!existingChapterId) {
      this.setInitialChapter();
    } else {
      this.setChapter();
    }

    this.select.addEventListener("change", () => {
      this.setChapter();
    });
  }

  setInitialChapter() {
    // Get closest chapters from our API
    fetch(`/api/v1/chapters/nearest`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.chapters.length) {
          const nearestChapter = data.chapters[0];
          const option = this.el.querySelector(
            "option[data-id='" + nearestChapter.id + "']"
          );
          option.setAttribute("selected", "selected");
          this.selected = option;
          this.updateChapter();
        }
      });
  }

  setChapter() {
    this.selected = this.el.querySelector(".MyChapter-select option:checked");
    this.updateChapter();
  }

  updateChapter() {
    const chapterId = this.selected.getAttribute("data-id");
    Cookies.set("chapter_id", chapterId);
    this.link.href = this.selected.value;

    // Dynamically update the width of the select menu
    this.el.removeAttribute("data-hidden");
    this.tmpWidthOption.innerHTML = this.selected.innerHTML;
    this.select.style.width = this.tmpWidth.offsetWidth + "px";
  }
}

document.querySelectorAll(".MyChapter").forEach((el) => new MyChapter(el));
