// Add icon to external links
import $ from 'jquery';
import utils from './utils';
import cookie from '../lib/cookie';

/**
 * Dismiss requires a toggle and content with matching data attributes values
 * Optionally you can set an expiration length as a data attribute on the toggle
 * When a toggle is pressed, the content is removed and a cookie is set with the
 * assigned expiration date.
 * Onload, if the content associated cookie does NOT exist, the content will be
 * shown
 *
 * Element attributes:  (*=required)
 * Toggle  - *[data-dismiss-toggle="UNIQUE_ID"]
 *            [data-dismiss-expiration="NUMBER"]
 * Content - *[data-dismiss-content="UNIQUE_ID"]
 *
 * Example Markup:
 * <button data-dismiss-toggle="alert-UNIQUE_ID" data-dismiss-expiration="5"></button>
 * ...
 * <div data-dismiss-content="alert-UNIQUE_ID"></div>
 */

$('[data-dismiss-toggle]').each(function() {
  var $this = $(this);
  var id         = $this.attr('data-dismiss-toggle');
  var expiration = $this.attr('data-dismiss-expiration');
  var $content   = $('[data-dismiss-content="'+id+'"]');
  var cookieData = {
    name: id
  };
  // Set expiration settings if expiration data-attr exists
  if (expiration) {
    cookieData.settings = {
      expires: parseFloat(expiration)
    };
  }
  // IF the cookie does not exist
  if (!cookie(id)) {
    // SHOW the content
    $content.show();
    $this.removeClass('-hidden');
  }
  else {
    // REMOVE the content
    // may want to disable this if the content needs shown by some other
    // event triggered by the user
    $content.remove();
  }
  // Handle toggle clicks
  $this.on('click', function() {
    // Hide the content AND remove on completion
    if ($content.hasClass('ModalAlert')) {
      $content.fadeOut(function() {
        $(this).remove();
      });
    } else {
      $content.slideUp(function() {
        $(this).remove();
      });
    }
    // Set the unique cookie with a defined expiration
    cookie(cookieData.name, true, cookieData.settings.expires);
  });
});
