//------------------------------------------------------------------------
// Additional functionality for Higher Logic forms (e.g. Sign Up)
//
// Higher Logic embed code exmaple:
//
//   <div id="RM-target-lp-22339"></div>
//   <script src="https://ffblindnes.realmagnet.land/script/22339"></script>
//
// Included functionality:
// - Sets value of “input.js-timestamp” to current date (MM/DD/YYYY)
//------------------------------------------------------------------------
'use strict';

class HigherLogic {
  constructor(el) {
    this.el = el;

    // We’re using MutationObserver() to detect when the form has
    // been injected into the DOM by Higher Logic’s JS, since they
    // don’t provide an API or callback function.
    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    if ('MutationObserver' in window) {
      this.observer = new MutationObserver(() => {
        this.formReadyCallback();
      });

      this.observer.observe(this.el, {childList: true});
    } else {
      // TODO: Use setTimeout() if MutationObserver isn’t supported (IE10-)
      // https://caniuse.com/#feat=mutationobserver
      console.warn('No MutationObserver() support, can’t run custom Higher Logic script');
      return false;
    }
  }

  // Runs once form has been added to the DOM
  formReadyCallback() {
    let forms = this.el.querySelectorAll('form');

    forms.forEach(form => {
      this.initTimestamp(form);
      // init other custom form functionality here
    });

    // Disconnect MutationObserver to avoid running the callback again
    // just in case something alters the form markup later on.
    this.observer.disconnect();
  }

  // Set the timestamp field value(s) to current date
  initTimestamp(form) {
    let dateInputs = form.querySelectorAll('input.js-timestamp');

    dateInputs.forEach(input => {
      input.value = this.getDate();
    });
  }

  // Pad single char with a leading zero
  padZero(string) {
    // Slice trick via https://stackoverflow.com/a/30272803
    return ('0' + string).slice(-2);
  }

  // Get current date formatted as “MM/DD/YYYY”
  getDate() {
    let now = new Date();
    // Note: getMonth() starts at zero so we need to add 1
    let month = this.padZero(now.getMonth() + 1);
    let date = this.padZero(now.getDate());
    let year = now.getFullYear();

    return `${month}/${date}/${year}`;

    // NOTE: Higher Logic only seems to support date, not time
    // let hours = padZero(now.getHours());
    // let minutes = padZero(now.getMinutes());
    // let seconds = padZero(now.getSeconds());
    // return `${month}/${date}/${year}, ${hours}:${minutes}:${seconds}`;
  }
}

// The Higher Logic embed code looks like this:
//
//   <div id="RM-target-lp-22339"></div>
//   <script src="https://ffblindnes.realmagnet.land/script/22339"></script>
//
// Since the ID number could change, we’re targeting IDs that begin with “RM-target”
document.querySelectorAll('[id^="RM-target"]').forEach(el => new HigherLogic(el));
