//------------------------------------------------------------------------
// This file contains modules that DO NOT depend on the CSSOM and can be
// executed as soom as the CSSOM is ready. It’s set to “defer” in the HTML.
//------------------------------------------------------------------------

// Picture element polyfill
// Required for IE 11- and Android 4.4-
// http://caniuse.com/#feat=picture
import "picturefill";

// Detect input method in order to hide outlines in an accessible manner
// https://github.com/ten1seven/what-input
import "what-input";

// Modules
import "../modules/background-picture";
import "../modules/chapter-filter";
import "../modules/dismiss";
import "../modules/find-a-chapter";
// import '../modules/fluid-svg-polyfill';
import "../modules/form-validation";
import "../modules/higher-logic-forms";
import "../modules/link-icons";
import "../modules/mailto";
import "../modules/modal-alert";
import "../modules/my-chapter";
import "../modules/orphans";
import "../modules/recite-me-toggle";
import "../modules/scrollable-tables";
import "../modules/search-filters";
import "../modules/soundcloud";
import "../modules/tabs";
import "../modules/vimeo";
import "../modules/youtube";
import "../modules/back-to-top";

import ImageGenerator from "../modules/image-generator";

new ImageGenerator();
