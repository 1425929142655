//------------------------------------------------------------------------
// Append icons to links and prevent them from wrapping
//------------------------------------------------------------------------
import Unorphanize from "@threespot/unorphanize";

// Use custom rounding function to avoid issues with Number.toFixed() and Math.round()
// http://www.jacklmoore.com/notes/rounding-in-javascript/
const round = (number, precision) => {
  return Number(Math.round(number + "e" + precision) + "e-" + precision);
};

// FIXME: Replace example icons
const icons = {
  arrow: {
    class: "icon icon-arrow",
    viewBox: "0 0 207 365",
    width: 10,
    // height: 13, // optionally set the default height
    path: `<path d="M0 340V25C0 3 26-8 42 7l158 158c9 10 9 25 0 35L42 358c-16 15-42 4-42-18z"/>`
    // customAttrs: '' // optional additional attrs (e.g. fill="none" stroke="#fff" stroke-width="1")
  },
  chevDown: {
    class: "icon icon-chevDown",
    viewBox: "0 0 14 10",
    width: 12,
    path: `<path d="M12 0l2 2-7 7.1-7-7L2-.1l5 5z"/>`
  },
  download: {
    class: "icon icon-download",
    viewBox: "0 0 384 512",
    width: 12,
    path: `<path d="M360 480H24c-13.3 0-24-10.7-24-24v-24c0-13.3 10.7-24 24-24h336c13.3 0 24 10.7 24 24v24c0 13.3-10.7 24-24 24zM128 56v136H40.3c-17.8 0-26.7 21.5-14.1 34.1l152.2 152.2c7.5 7.5 19.8 7.5 27.3 0l152.2-152.2c12.6-12.6 3.7-34.1-14.1-34.1H256V56c0-13.3-10.7-24-24-24h-80c-13.3 0-24 10.7-24 24z"/>`
  },
  external: {
    class: "icon icon-external",
    viewBox: "0 0 576 512",
    width: 13,
    path: `<path d="M448 279v185c0 27-21 48-48 48H48c-27 0-48-21-48-48V112c0-27 21-48 48-48h248a24 24 0 0 1 17 7l16 16c15 15 4 41-17 41H64v320h320V295c0-6 3-12 7-17l16-16c15-15 41-4 41 17zM576 37c0-20-17-37-37-37H380c-15 0-28 13-28 28v18c0 16 13 28 29 28l67-2-249 247c-9 9-9 25 0 34l24 24c9 9 25 9 34 0l247-249-2 67c0 16 12 29 28 29h18c15 0 28-13 28-28V37z"/>`
  }
};

// Note: Set height and width in rems via inline style to fix scaling issues in IE 11-
//
// We prefer to set inline height and width to prevent SVGs from being
// huge if the CSS fails to load or is slow to load when async.
// However, IE 11- doesn’t properly scale SVGs when the height or width is
// set to “auto” even with “viewBox” and “preserveAspectRatio” attributes.
// Setting the heigth and width in rems via inline styles allows the icons
// to scale with the base font size. Modern browsers can still adjust the
// size via CSS but will require “!important” and should use @supports{}
// to exclude IE 11- https://caniuse.com/#feat=css-featurequeries
const buildSVG = function(icon) {
  return `
    <svg class="${icon.class}" viewBox="${icon.viewBox}" style="height: ${icon.height}rem; width: ${icon.width}rem" preserveAspectRatio="xMidYMid meet" aria-hidden="true" focusable="false" ${icon.customAttrs}>
      ${icon.path}
    </svg>`;
};

const fileTypes = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx"];

const nodes = document.querySelectorAll("[data-icon]");

nodes.forEach(function(el) {
  let iconName = el.getAttribute("data-icon");

  // Check if icon exists
  if (!(iconName in icons)) {
    console.warn(`Icon “${iconName}” was not found in link-icons.js`, el);
    return false;
  }

  // Automatically change the icon if it’s external or a download link
  // NOTE: This is project-specific, edit as needed.
  if (!el.classList.contains("FIXME-dont-change-icon")) {

    // If link is external, use external icon (excluding certain CTA links/buttons below)
    // External link test from https://gist.github.com/jlong/2428561
    var a = document.createElement("a");
    a.href = el.href;
    if (a.hostname !== window.location.hostname) {
      iconName = "external";
    }

    // Check if link is a file download
    let fileExt = a.pathname.split(".").pop();

    if (fileTypes.indexOf(fileExt) > -1) {
      iconName = "download";
    }
  }

  // Create new object for this icon so we can change
  // the dimensions without affecting the defaults.
  const icon = {};

  // Copy values from original icons object
  Object.assign(icon, icons[iconName]);

  // Check for custom size attributes
  let iconHeight = el.getAttribute("data-icon-height");
  let iconWidth = el.getAttribute("data-icon-width");

  // Validate “data-icon-height” and “data-icon-width” values if present
  // Note: Use unary plus (+) operator to convert strings to numbers
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
  if (iconHeight) {
    if (isNaN(+iconHeight)) {
      console.warn(`Can’t parse data-icon-height value of “${iconHeight}” on ${el}`);
      return false;
    } else {
      icon.height = +iconHeight;
    }
  }

  if (iconWidth) {
    if (isNaN(+iconWidth)) {
      console.warn(`Can parse data-icon-width value of “${iconWidth}” on ${el}`);
      return false;
    } else {
      icon.width = +iconWidth;
    }
  }

  // Make sure either the height or width has been defined
  if (!icon.height && !icon.width) {
    console.warn(`No height or width defined for icon “${iconName}”`, icon);
    return false;
  }

  // Calculate height or width if only one dimension was provided
  // Note: We can’t rely on CSS to resize SVGs in IE11-
  //       because IE doesn’t respect the viewBox ratio.
  let viewBoxArr = icon.viewBox.split(" ");

  // Validate viewBox value
  if (viewBoxArr.length !== 4) {
    console.warn(`Icon “${iconName}” has a malformed viewBox attribute: “${icon.viewBox}”`);
    return false;
  }

  // Calculate aspect ratio
  let aspectRatio = +viewBoxArr[2] / +viewBoxArr[3];

  // Calculate height if width was provided
  if (!icon.height && icon.width) {
    icon.height = icon.width / aspectRatio;
  }

  // Calculate width if height was provided
  if (!icon.width && icon.height) {
    icon.width = icon.height * aspectRatio;
  }

  // Convert height and width to rems, round to 3 decimal places
  icon.height = round(icon.height / 16, 3);
  icon.width = round(icon.width / 16, 3);

  // Insert the icon using Unorphanize to prevent wrapping
  new Unorphanize(el, {
    inlineStyles: false,
    className: "u-nowrap",
    append: buildSVG(icon)
  });
});
